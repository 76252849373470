@import "../rem";

.view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  perspective: 0;
}

// .plane {
//   width: rem(400px);
//   height: rem(400px);
//   transform-style: preserve-3d;
// }

.main {
  width: rem(400px);
  height: rem(400px);
  // transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  // transform: rotateX(60deg) rotateZ(-30deg);
  // -webkit-animation: view 20s infinite linear;
  // animation: view 20s infinite linear;

  svg, img {
    width: rem(400px);
    height: rem(400px);
  }
}

// .plane {
//   position: absolute;
//   animation: move 10s infinite linear reverse;
// }

// .plane .circle {
//   -webkit-animation: scale 5s infinite cubic-bezier(0, 1, 1, 1) alternate;
//   animation: scale 5s infinite cubic-bezier(0, 1, 1, 1) alternate;
// }

// .plane:nth-child(1) {
//   -webkit-animation-delay: -1s;
//   animation-delay: -1s;
// }

// .plane:nth-child(1) .circle {
//   -webkit-animation-delay: -1s;
//   animation-delay: -1s;
// }

// .plane:nth-child(2) {
//   -webkit-animation-delay: -2s;
//   animation-delay: -2s;
// }

// .plane:nth-child(2) .circle {
//   -webkit-animation-delay: -2s;
//   animation-delay: -2s;
// }

// .plane:nth-child(3) {
//   -webkit-animation-delay: -3s;
//   animation-delay: -3s;
// }

// .plane:nth-child(3) .circle {
//   -webkit-animation-delay: -3s;
//   animation-delay: -3s;
// }

// .plane:nth-child(4) {
//   -webkit-animation-delay: -4s;
//   animation-delay: -4s;
// }

// .plane:nth-child(4) .circle {
//   -webkit-animation-delay: -4s;
//   animation-delay: -4s;
// }

// .plane:nth-child(5) {
//   -webkit-animation-delay: -5s;
//   animation-delay: -5s;
// }

// .plane:nth-child(5) .circle {
//   -webkit-animation-delay: -5s;
//   animation-delay: -5s;
// }

// .plane:nth-child(6) {
//   -webkit-animation-delay: -6s;
//   animation-delay: -6s;
// }

// .plane:nth-child(6) .circle {
//   -webkit-animation-delay: -6s;
//   animation-delay: -6s;
// }

// .plane:nth-child(7) {
//   -webkit-animation-delay: -7s;
//   animation-delay: -7s;
// }

// .plane:nth-child(7) .circle {
//   -webkit-animation-delay: -7s;
//   animation-delay: -7s;
// }

// .plane:nth-child(8) {
//   -webkit-animation-delay: -8s;
//   animation-delay: -8s;
// }

// .plane:nth-child(8) .circle {
//   -webkit-animation-delay: -8s;
//   animation-delay: -8s;
// }

// .plane:nth-child(9) {
//   -webkit-animation-delay: -9s;
//   animation-delay: -9s;
// }

// .plane:nth-child(9) .circle {
//   -webkit-animation-delay: -9s;
//   animation-delay: -9s;
// }

// .plane:nth-child(10) {
//   -webkit-animation-delay: -10s;
//   animation-delay: -10s;
// }

// .plane:nth-child(10) .circle {
//   -webkit-animation-delay: -10s;
//   animation-delay: -10s;
// }

// .circle {
//   background: rgba(255, 255, 255, 0.25);
//   border-radius: 100%;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   box-shadow: inset 0 0 rem(40px) rgba(255, 255, 255, 1), 0 0 rem(20px) rgba(255, 255, 255, 0.5);
// }

// @keyframes move {
//   0% {
//     transform: translateZ(rem(-200px));
//   }
//   100% {
//     transform: translateZ(rem(200px));
//   }
// }

// @keyframes scale {
//   0% {
//     transform: scale(0, 0);
//   }
//   100% {
//     transform: scale(1, 1);
//   }
// }

// @keyframes view {
//   0% {
//     transform: rotateZ(0deg) rotateX(45deg);
//   }
//   100% {
//     transform: rotateZ(360deg) rotateX(45deg);
//   }
// }

.names {
  text-transform: uppercase;
  font-weight: bolder;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: rem(400px);
  height: rem(400px);
  font-size: rem(30px);
}

.names div {
  position: absolute;
}

.canada {
  top: rem(-158px);
  left: rem(133px);
}

.peru {
  top: rem(-114px);
  left: rem(269px);
  font-size: rem(18px);
}

.austria {
  top: rem(-76px);
  left: rem(210px);
  font-size: rem(20px);
}

.estonia {
  top: rem(412px);
  left: rem(213px);
  font-size: rem(25px);
}

.england {
  top: rem(509px);
  left: rem(52px);
  font-size: rem(16px);
}

.india {
  top: rem(478px);
  left: rem(225px);
  font-size: rem(20px);
}

.taiwan {
  top: rem(444px);
  left: rem(19px);
  font-size: rem(20px);
}

.hongKong {
  top: rem(-54px);
  left: rem(22px);
  font-size: rem(25px);
}

.holland {
  top: rem(-104px);
  left: rem(16px);
  font-size: rem(16px);
}

@media (min-width: 800px) {
  .names div {
    font-size: inherit;
  }

  .canada {
    top: rem(-48px);
    left: rem(133px);
  }

  .peru {
    top: rem(14px);
    left: rem(356px);
  }

  .austria {
    top: rem(100px);
    left: rem(417px);
  }

  .estonia {
    top: rem(272px);
    left: rem(423px);
  }

  .england {
    top: rem(420px);
    left: rem(258px);
  }

  .india {
    top: rem(413px);
    left: rem(-31px);
  }

  .taiwan {
    top: rem(300px);
    left: rem(-152px);
  }

  .hongKong {
    top: rem(100px);
    left: rem(-219px);
  }

  .holland {
    top: rem(27px);
    left: rem(-118px);
  }
}
