.root {
  z-index: 101;
  position: fixed;
  width: 25rem;
  height: 25rem;
  font-size: 1rem;
}
@media (min-width: 800px) {
  .root {
    width: 37.5rem;
    height: 37.5rem;
    max-width: initial;
    max-height: initial;
  }
}

.ring {
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .root {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: 50%;
    left: -12.5rem;
  }
}
@media (min-width: 800px) {
  .left {
    top: 13.5625rem;
    left: 3.0625rem;
  }
}
@media (min-width: 800px) {
  .right {
    top: 13.5625rem;
    right: 12.0625rem;
  }
}
.content::before {
  content: "";
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: polygon(0 0, 98% 0, 50% 6%, 23.4% 17.3%, 6% 32.6%, 0 50%, 6% 65.6%, 23.4% 82.7%, 50% 94%, 98% 100%, 0 100%);
  shape-margin: 7%;
}

.inner::before {
  content: "";
  width: 50%;
  height: 100%;
  float: right;
  shape-outside: polygon(2% 0%, 100% 0%, 100% 100%, 2% 100%, 50% 94%, 76.6% 82.7%, 94% 65.6%, 100% 50%, 94% 32.6%, 76.6% 17.3%, 50% 6%);
  shape-margin: 7%;
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  line-height: 1.3;
  right: 1.25rem;
  left: 1.25rem;
}
@media (min-width: 800px) {
  .content {
    right: 3.125rem;
    left: 3.125rem;
  }
}

.content p {
  margin-top: 0;
}

.content p:first-child {
  padding-top: 5rem;
}
@media (min-width: 800px) {
  .content p:first-child {
    padding-top: 13rem;
  }
}

.inner {
  height: 100%;
}

.headline {
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  left: 7.5rem;
}
@media (min-width: 800px) {
  .headline {
    font-size: 1.5625rem;
    left: 17.5rem;
  }
}

@media (min-width: 800px) {
  .left .headline {
    left: 12.5rem;
  }
}

.headline + .content p:first-child {
  padding-top: 9.375rem;
}
@media (min-width: 800px) {
  .headline + .content p:first-child {
    padding-top: 15.625rem;
  }
}

.circle {
  position: absolute;
  z-index: 0;
}