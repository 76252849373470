@media (max-width: 800px) {
  .root {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 4.375rem;
    transform: translateY(15.625rem);
    z-index: 1;
  }
}
.navigating .info,
.navigating .work,
.navigating .video,
.navigating .renderings {
  opacity: 0;
}

.info,
.work,
.video,
.renderings {
  border-radius: 999em;
  width: 3.75rem;
  height: 3.75rem;
  display: block;
  position: relative;
  transition: all 0.25s;
  opacity: 1;
  border-color: grey;
  border-width: 3px;
  border-style: solid;
}
.info::before,
.work::before,
.video::before,
.renderings::before {
  background-image: var(--bgi);
  background-size: cover;
  background-position: center;
  background-color: var(--profile-color);
  content: " ";
  border-radius: 999em;
  position: absolute;
  top: 0.0625rem;
  right: 0.0625rem;
  bottom: 0.0625rem;
  left: 0.0625rem;
}

.selected {
  border-color: white;
}

@media (min-width: 800px) {
  .info,
.work,
.video,
.renderings {
    position: absolute;
    width: 8.125rem;
    height: 8.125rem;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 401;
    border-width: 3px;
  }
  .info::before,
.work::before,
.video::before,
.renderings::before {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
  }
  .info:hover,
.work:hover,
.video:hover,
.renderings:hover {
    border-width: 8px;
  }
  .info.selected,
.work.selected,
.video.selected,
.renderings.selected {
    border-width: 8px;
  }

  .info {
    transform: translate(22.7331668493rem, -13.125rem) scale(1);
  }

  .navigating .info {
    transform: translate(22.7331668493rem, -13.125rem) scale(0);
  }

  .video {
    transform: translate(25.9616664133rem, -3.8799970422rem) scale(1);
  }

  .navigating .video {
    transform: translate(25.9616664133rem, -3.8799970422rem) scale(0);
  }

  .work {
    transform: translate(25.5772142006rem, 5.9049651765rem) scale(1);
  }

  .navigating .work {
    transform: translate(25.5772142006rem, 5.9049651765rem) scale(0);
  }

  .renderings {
    transform: translate(21.6333124513rem, 14.8681637193rem) scale(1);
  }

  .navigating .renderings {
    transform: translate(21.6333124513rem, 14.8681637193rem) scale(0);
  }
}