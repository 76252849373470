.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  /* transition: 1s background-image; */
  background-position-y: center;
  transition: all 1s;
}

.section {
  position: fixed;
  top: 3.4375rem;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-size: cover;
  /* transition: 1s background-image; */
  background-position-y: center;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 800px) {
  .section {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    top: 0rem;
    width: 38.75rem;
    height: 28.125rem;
  }
}

.imageSequenceContainer,
.videoSequenceContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 18.75rem;
  height: 21.875rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -5.625rem;
  margin-bottom: -5rem;
  transform: scale(0.6);
}
@media (min-width: 360px) {
  .imageSequenceContainer,
.videoSequenceContainer {
    margin-top: -3.125rem;
    margin-bottom: -1.875rem;
    transform: scale(0.75);
  }
}
@media (min-width: 800px) {
  .imageSequenceContainer,
.videoSequenceContainer {
    height: 25rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    transform: scale(1);
  }
}

.videoSequenceContainer {
  align-items: center;
}
@media (min-width: 800px) {
  .videoSequenceContainer {
    align-items: flex-start;
  }
}
.videoSequenceContainer .masked {
  mask-size: 95%;
}
@media (min-width: 800px) {
  .videoSequenceContainer .masked {
    mask-size: auto;
  }
}

.imageSequenceBackground {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 15.625rem;
  height: 22.1875rem;
  position: absolute;
  z-index: 1;
}
@media (min-width: 800px) {
  .imageSequenceBackground {
    width: 18.75rem;
    height: 25.125rem;
  }
}

.imageSequenceLogo {
  background-size: contain;
  background-repeat: no-repeat;
  width: 12.8125rem;
  height: 4.6875rem;
  position: absolute;
  z-index: 2;
  transform: translate(-3.125rem, 0.3125rem);
}
@media (min-width: 800px) {
  .imageSequenceLogo {
    transform: translate(-4.375rem, 0.3125rem);
  }
}

.sequenceCacher {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}
.sequenceCacher div {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

.imageSequence {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1s;
  width: 15.625rem;
  height: 22.1875rem;
  position: relative;
  z-index: 3;
}
@media (min-width: 800px) {
  .imageSequence {
    width: 18.75rem;
    height: 25.125rem;
  }
}

.imageSequenceContainer > .imageSequence:not(.masked) {
  background-size: contain;
}
@media (min-width: 800px) {
  .imageSequenceContainer > .imageSequence:not(.masked) {
    margin-top: -32px;
  }
}
.imageSequenceContainer div:nth-of-type(2) + div {
  background-size: cover !important;
  transform: translate(2.1875rem, 1.25rem);
}
@media (min-width: 800px) {
  .imageSequenceContainer div:nth-of-type(2) + div {
    margin-top: 0px !important;
  }
}

.video {
  width: 18.75rem;
  height: 21.875rem;
}
@media (min-width: 800px) {
  .video {
    height: 25rem;
  }
}
.video video {
  height: 100%;
  width: 100%;
}

.masked {
  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: auto;
}

.contentList {
  line-height: 1.3;
  font-size: 1rem;
}
@media (max-width: 800px) {
  .contentList {
    height: 15.625rem;
    width: 100%;
  }
}

.content {
  transition: all 1s;
}
.contentContainer {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-start;
  position: relative;
  padding: 0 1.25rem;
  padding-top: 1.25rem;
}
@media (min-width: 800px) {
  .contentContainer {
    padding-top: 2.5rem;
  }
}
.contentContainer .contentList {
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-start;
  position: relative;
}
.contentContainer .contentList .content {
  position: absolute;
  top: 0;
  font-size: small;
}
.contentContainer .contentList .content:last-of-type {
  position: static;
}
.contentContainer .contentList .content:only-of-type {
  position: relative;
}

.headline {
  width: 100%;
}
.headline h2 {
  margin: 0;
  font-size: 1.25rem;
  text-align: left;
}
@media (min-width: 800px) {
  .headline h2 {
    text-align: left;
    font-size: 1.4375rem;
  }
}

.menu {
  position: absolute;
  bottom: -12.1875rem;
  width: 21.25rem;
  max-height: 21.25rem;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 4;
}
@media (min-width: 800px) {
  .menu {
    margin: initial;
    bottom: -6.25rem;
    right: -6.25rem;
    left: initial;
  }
}

.menu > svg {
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 800px) {
  .menu > svg {
    opacity: 1;
  }
}

.menuItems {
  position: absolute;
  text-transform: uppercase;
  font-size: 0.625rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 4.375rem;
  text-align: center;
  align-items: center;
}
@media (min-width: 800px) {
  .menuItems {
    display: block;
    right: 7.5rem;
    top: 3.125rem;
    font-size: 0.875rem;
    justify-content: center;
  }
}

.menuItems a {
  display: block;
  text-decoration: none;
  color: inherit;
}
@media (min-width: 800px) {
  .menuItems a {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 1.25rem;
  }
}

.menuItems svg {
  margin-bottom: 10px;
  height: 22px;
}
@media (min-width: 800px) {
  .menuItems svg {
    margin-bottom: 0;
    width: 32px;
    height: initial;
    margin-left: 10px;
  }
}

.floaters svg {
  position: absolute;
  z-index: 1;
}

.floaters svg:nth-child(1) {
  animation: float1 5s linear infinite;
}

@keyframes float1 {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(1000px);
  }
}
.floaters svg:nth-child(1) {
  transform: translateY(-3000px);
  animation: float1 7s linear infinite;
  animation-delay: 15s;
}

@keyframes float1 {
  0% {
    transform: scale(1.1425095589) translate(176px, -1755px);
  }
  100% {
    transform: scale(1.1425095589) translate(176px, 3820px);
  }
}
.floaters svg:nth-child(2) {
  transform: translateY(-3000px);
  animation: float2 8s linear infinite;
  animation-delay: 19s;
}

@keyframes float2 {
  0% {
    transform: scale(0.616563549) translate(203px, -1632px);
  }
  100% {
    transform: scale(0.616563549) translate(203px, 3048px);
  }
}
.floaters svg:nth-child(3) {
  transform: translateY(-3000px);
  animation: float3 8s linear infinite;
  animation-delay: 13s;
}

@keyframes float3 {
  0% {
    transform: scale(0.6109941414) translate(392px, -1670px);
  }
  100% {
    transform: scale(0.6109941414) translate(392px, 3580px);
  }
}
.floaters svg:nth-child(4) {
  transform: translateY(-3000px);
  animation: float4 7s linear infinite;
  animation-delay: 7s;
}

@keyframes float4 {
  0% {
    transform: scale(1.2160892301) translate(424px, -1690px);
  }
  100% {
    transform: scale(1.2160892301) translate(424px, 3988px);
  }
}
.floaters svg:nth-child(5) {
  transform: translateY(-3000px);
  animation: float5 9s linear infinite;
  animation-delay: 9s;
}

@keyframes float5 {
  0% {
    transform: scale(1.4963705374) translate(533px, -1743px);
  }
  100% {
    transform: scale(1.4963705374) translate(533px, 3761px);
  }
}
.floaters svg:nth-child(6) {
  transform: translateY(-3000px);
  animation: float6 8s linear infinite;
  animation-delay: 1s;
}

@keyframes float6 {
  0% {
    transform: scale(0.6424469176) translate(649px, -1572px);
  }
  100% {
    transform: scale(0.6424469176) translate(649px, 3148px);
  }
}
.floaters svg:nth-child(7) {
  transform: translateY(-3000px);
  animation: float7 9s linear infinite;
  animation-delay: 20s;
}

@keyframes float7 {
  0% {
    transform: scale(1.4979396218) translate(727px, -1773px);
  }
  100% {
    transform: scale(1.4979396218) translate(727px, 2557px);
  }
}
.floaters svg:nth-child(8) {
  transform: translateY(-3000px);
  animation: float8 7s linear infinite;
  animation-delay: 13s;
}

@keyframes float8 {
  0% {
    transform: scale(1.4940519603) translate(841px, -1712px);
  }
  100% {
    transform: scale(1.4940519603) translate(841px, 2252px);
  }
}
.floaters svg:nth-child(9) {
  transform: translateY(-3000px);
  animation: float9 9s linear infinite;
  animation-delay: 18s;
}

@keyframes float9 {
  0% {
    transform: scale(1.1752708253) translate(946px, -1578px);
  }
  100% {
    transform: scale(1.1752708253) translate(946px, 3662px);
  }
}
.floaters svg:nth-child(10) {
  transform: translateY(-3000px);
  animation: float10 7s linear infinite;
  animation-delay: 9s;
}

@keyframes float10 {
  0% {
    transform: scale(1.0019568084) translate(1008px, -1742px);
  }
  100% {
    transform: scale(1.0019568084) translate(1008px, 2441px);
  }
}
.backgroundVideo,
.backgroundVideo video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}