@import "rem";
@import "sizes";

.root {
  //   overflow: hidden;
  border-radius: 999px;
  position: absolute;

  width: rem($mobile-size);
  height: rem($mobile-size);
  transform: translateY(rem(-50px)) translateX(-50%);
  left: 50%;
  z-index: 5;

  @media (min-width: 800px) {
    width: rem($desktop-size);
    height: rem($desktop-size);
    left: 0;
    transform: none;
  }

  border-radius: 999em;

  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.slide {
  width: 10000px;
  position: relative;
  display: flex;
  z-index: 5;
}

.photo {
  position: relative;
  width: rem($mobile-size);
  height: rem($mobile-size);

  @media (min-width: 800px) {
    width: rem($desktop-size);
    height: rem($desktop-size);
  }

  background-size: cover;
  //   background-position: center;
  z-index: 4;
  //   opacity: 0.5;
}

.arrows {
  svg {
    cursor: pointer;
    padding: rem(10px); // allow for bigger hit area
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 6;
    margin: auto;

    @media (max-width: 800px) {
      width: rem(15px);
      height: rem(15px);
      &:not(.right) {
        left: rem(13px);
      }
    }
  }

  &.renderingsArrows svg path {
    stroke: var(--profile-color);
  }

  svg.right {
    right: 0;

    @media (max-width: 800px) {
      right: rem(13px);
    }
  }

  @media (min-width: 800px) {
    right: rem(20px);
    left: rem(20px);
  }
}

// @media (max-width: 800px) {
//   .largeArrows svg {
//     width: rem(30px);
//     height: rem(30px);
//   }
// }

.profileName {
  position: absolute;
  text-transform: uppercase;
  text-align: left;
  transition: 0.1s opacity;
  opacity: 0;
  font-size: rem(22px);
  pointer-events: none;

  strong {
    font-size: rem(28px);
  }

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 99999;
  width: rem(220px);
  height: rem(150px);
  transform: translate(rem(100px), rem(-220px));

  @media (min-width: 800px) {
    font-size: rem(15px);
    transform: translate(rem(130px), rem(-295px));
    font-size: 28px;
    /* text-shadow: var(--profile-color) 0.5px 0.5px; */

    strong {
      font-size: rem(36px);
    }
  }
}

.wornBy {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-size: rem(12px);
  position: absolute;
  bottom: rem(80px);
  width: rem(150px);

  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 5;
}

.wornBy strong {
  font-size: rem(22px);
}

.instagram {
  // position: absolute;
  // bottom: 80px;
  width: rem(30px);
  height: rem(30px);
  margin-right: rem(5px);

  // right: 0;
  // left: 0;
  // margin: auto;
}
