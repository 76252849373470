.popup {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.closeButton {
  position: absolute;
  z-index: 2;
  top: 1.25rem;
  left: 1.25rem;
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
}
.closeButton line {
  stroke: #fff;
}