.toggle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}
.toggle svg {
  width: 6.25rem;
  height: 6.25rem;
  cursor: pointer;
}

.root,
.video video,
.video {
  width: 100%;
  height: 100%;
}

.video {
  background-color: #000;
}

.zoom {
  position: relative;
  margin-left: -23.0263157895rem;
  left: 50%;
  width: 43.75rem;
  height: 100%;
}
@media (min-width: 800px) {
  .zoom {
    margin-left: -21.875rem;
  }
}
.zoom video {
  width: auto;
  height: 100%;
}
@media (min-width: 800px) {
  .zoom video {
    width: 43.75rem;
  }
}

.zoom {
  width: 150%;
}
@media (min-width: 800px) {
  .zoom {
    width: auto;
    left: 0;
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  .zoom video {
    width: auto;
  }
}

.controls {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}