.logoContainer {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  z-index: 100;
}
.logo h1 {
  font: normal normal 16px "zona_proextrabold", Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
}
.logo h1 small,
.logo h1 sup {
  font: normal normal 16px "zona_prolight", Arial, sans-serif;
}
.logo h1 {
  font-size: 1.875rem;
}
.logo h1 small,
.logo h1 sup {
  font-size: 1.25rem;
}
@media (min-width: 800px) {
  .logo h1 {
    font-size: 3.6625rem;
  }
  .logo h1 small,
.logo h1 sup {
    font-size: 2.4375rem;
  }
}
@media (min-width: 1400px) {
  .logo h1 {
    font-size: 6.1625rem;
  }
  .logo h1 small,
.logo h1 sup {
    font-size: 4.25rem;
  }
}