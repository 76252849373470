@import "vars.scss";
@import "rem";


.logoContainer {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  z-index: 100;

  h1 {
    font: $zona-pro-extra-bold;
    text-align: center;
    text-transform: uppercase;

    small,
    sup {
      font: $zona-pro-light;
    }
  }

  h1 {
    font-size: rem(30px);
    small,
    sup {
      font-size: rem(20px);
    }
  }

  @media (min-width: 800px) {
    h1 {
      font-size: rem(58.6px);
      small,
      sup {
        font-size: rem(39px);
      }
    }
  }

  @media (min-width: 1400px) {
    h1 {
      font-size: rem(98.6px);
      small,
      sup {
        font-size: rem(68px);
      }
    }
  }
}
