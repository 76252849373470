@import "rem";

:root {
  --profile-mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MDAgNDAwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48Y2lyY2xlIGN4PSIyMDBweCIgY3k9IjIwMHB4IiByPSIyMDBweCIgZmlsbD0iYmxhY2siLz48L3N2Zz4=");
  --profile-mask-bg: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 1000 1000" preserveAspectRatio="none"><rect width="100%" height="100%" fill="white"/></svg>');
}

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  transition: 1s background-image;
  background-position-y: center;
}

@media (min-width: 800px) {
  .root {
    opacity: 1;
  }

  .root:not(.page) {
    mask: var(--profile-mask-bg), var(--profile-mask);
    -webkit-mask-composite: source-out;
    mask-composite: exclude;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%, rem(400px) rem(400px);
  }
}

.backgroundVideo {
  min-width: 100%;
  min-height: 100%;
}

.backgroundVideo video {
  min-width: 100%;
  min-height: 100%;
}

.hideBackgroundVideo {
  display: none;
}

@media (max-width: 800px) {
  .profileList {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
