@import "rem";

.root {
  margin: auto;
  right: 0;
  bottom: rem(25px);
  left: 0;
  position: absolute;
  justify-content: center;
  display: flex;
  z-index: 7;

  @media (min-width: 800px) {
    bottom: rem(40px);
  }
}

.button {
  width: rem(11px);
  height: rem(11px);
  border: rem(1px) solid white;
  border-radius: 999em;
  margin: 0 rem(3px);

  @media (min-width: 800px) {
    width: rem(13px);
    height: rem(13px);
  }
}

.active {
  background-color: white;
}
