@import "rem";
@import "sizes";

.root {
  position: relative;

  width: rem($mobile-size);
  height: rem($mobile-size);

  z-index: 400;
}

.root p {
  margin-top: 0;
}

.inner {
  height: 100%;
}

.root::before {
  content: "";
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: polygon(
    0 0,
    98% 0,
    50% 6%,
    23.4% 17.3%,
    6% 32.6%,
    0 50%,
    6% 65.6%,
    23.4% 82.7%,
    50% 94%,
    98% 100%,
    0 100%
  );
  shape-margin: 0%;
}

.inner::before {
  content: "";
  width: 50%;
  height: 100%;
  float: right;
  shape-outside: polygon(
    2% 0%,
    100% 0%,
    100% 100%,
    2% 100%,
    50% 94%,
    76.6% 82.7%,
    94% 65.6%,
    100% 50%,
    94% 32.6%,
    76.6% 17.3%,
    50% 6%
  );
  shape-margin: 0%;
}

.root::before,
.inner::before {
  shape-margin: 5%;
}

.root p {
  margin-top: 0;
}

.content {
  padding-top: rem(40px);
  text-align: center;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  height: 80%;
  position: absolute;
  padding: 0 10%;
  padding-top: 20%;
  box-sizing: border-box;
}

.page-1 {
  padding-top: rem(90px);
}

.headline {
  text-transform: uppercase;
  font-family: "tomato_groteskregular", Arial, sans-serif;
  font-size: rem(20px);
  line-height: rem(22px);
}

.headline strong {
  display: inline-block;
  font-family: "zona_proextrabold";
  font-size: rem(24px);

  &:before {
    content: '\00a0';
  }
}

.copy {
  margin-top: rem(35px);
  font-family: "tomato_groteskregular", Arial, sans-serif;
  font-size: rem(13px);

  strong {
    font-family: "tomato_groteskbold", Arial, sans-serif;
  }
}

.swatchLink {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: auto;
  top: rem(32px);
  right: 0;
  left: 0;
  width: auto;
  text-align: center;
  z-index: 1;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  small {
    font-family: "tomato_groteskregular", Arial, sans-serif;
    font-size: rem(12px);
    padding: rem(10px) 0;
  }
}

.swatchLink svg {
  max-height: rem(48px);
  max-width: rem(38px);
}

.swatchLink + div {
  &:first-of-type {
    .content {
      height: 100%;
      justify-content: flex-start;
      padding-top: rem(130px);
    }
  }
}

@media (max-width: 800px) {
  .content {
    font-size: rem(12px);
    line-height: 1.3;
  }
}

.page-1::before {
  content: " ";
  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center rem(240px);
  mask-repeat: no-repeat;
  mask-size: 115%;
  position: absolute;

  top: 0;
  right: rem(-18px);
  bottom: rem(-7px);
  left: rem(-17px);

  background-image: var(--profile-fabric);
  background-size: cover;

  @media (min-width: 800px) {
    mask-position: center rem(300px);
  }
}

.websiteLinks {
  position: absolute;
  display: flex;

  right: 0;
  bottom: rem(70px);
  left: 0;
  justify-content: center;

  z-index: 1;
}

.websiteLinks a {
  width: rem(30px);
  height: rem(30px);
}

.websiteLinks svg {
  width: 100%;
}

.websiteLinks a:first-child {
  margin-right: rem(20px);
}

.websiteLinks a:only-of-type {
  margin-right: 0;
}

@media (min-width: 800px) {
  .page-1::before {
    right: 0;
    bottom: 0;
    left: 0;
    mask-position: center rem(330px);
  }

  .websiteLinks {
    bottom: rem(88px);
  }

  .root {
    width: rem($desktop-size);
    height: rem($desktop-size);

    // margin: auto;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;

    z-index: 400;
    overflow: hidden;
  }

  // .content {
  //   padding-top: rem(150px);
  // }

  .root::before,
  .inner::before {
    shape-margin: 15%;
  }

  .headline {
    font-size: rem(30px);
    line-height: rem(32px);
  }

  .headline strong {
    font-size: rem(32px);
  }

  .copy {
    font-size: rem(20px);
  }

  // .swatchLink {
  //   top: rem(50px);
  // }
  /* .andrew-coimbra {
    padding-top: 200px;
  }

  .angus-tsui {
    padding-top: 200px;
  }

  .annaiss-yucra {
    padding-top: 200px;
  }

  .annelijn-hartevelt {
    padding-top: 200px;
  }

  .florentina-leitner {
    padding-top: 200px;
  }

  .jenn-lee {
    padding-top: 200px;
  }

  .katrin-aasmaa {
    padding-top: 200px;
  }

  */

  // .ns-gaia-sidharth-sinha {
  //   padding-top: rem(100px);
  // }

  // .silpa-myno-macheda-and-mehreen-asma {
  //   padding-top: rem(130px);
  // }

  // .two-point-two-anvita-sharma.page-0 {
  //   padding-top: 100px;
  // }
}
