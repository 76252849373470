.profile {
  width: 18.75rem;
  height: 18.75rem;
  position: absolute;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: auto;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 1s;
}
@media (min-width: 800px) {
  .profile {
    width: 25rem;
    height: 25rem;
    transform: scale(0.1);
  }
}

.profileContent {
  background-size: cover;
  border-radius: 999em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 800px) {
  .mobileScrollName {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}