.root {
  margin: auto;
  right: 0;
  bottom: 1.5625rem;
  left: 0;
  position: absolute;
  justify-content: center;
  display: flex;
  z-index: 7;
}
@media (min-width: 800px) {
  .root {
    bottom: 2.5rem;
  }
}

.button {
  width: 0.6875rem;
  height: 0.6875rem;
  border: 0.0625rem solid white;
  border-radius: 999em;
  margin: 0 0.1875rem;
}
@media (min-width: 800px) {
  .button {
    width: 0.8125rem;
    height: 0.8125rem;
  }
}

.active {
  background-color: white;
}