@import "rem";

.root {
  z-index: 101;
  position: fixed;
  width: rem(400px);
  height: rem(400px);
  font-size: rem(16px);

  @media (min-width: 800px) {
    width: rem(600px);
    height: rem(600px);
    max-width: initial;
    max-height: initial;
  }
}

.ring {
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .root {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: 50%;
    left: rem(-200px);
  }
}

@media (min-width: 800px) {
  .left {
    top: rem(217px);
    left: rem(49px);
  }
}

@media (min-width: 800px) {
  .right {
    top: rem(217px);
    right: rem(193px);
  }
}

.content::before {
  content: "";
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: polygon(
    0 0,
    98% 0,
    50% 6%,
    23.4% 17.3%,
    6% 32.6%,
    0 50%,
    6% 65.6%,
    23.4% 82.7%,
    50% 94%,
    98% 100%,
    0 100%
  );
  shape-margin: 7%;
}

.inner::before {
  content: "";
  width: 50%;
  height: 100%;
  float: right;
  shape-outside: polygon(
    2% 0%,
    100% 0%,
    100% 100%,
    2% 100%,
    50% 94%,
    76.6% 82.7%,
    94% 65.6%,
    100% 50%,
    94% 32.6%,
    76.6% 17.3%,
    50% 6%
  );
  shape-margin: 7%;
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  line-height: 1.3;
  right: rem(20px);
  left: rem(20px);

  @media (min-width: 800px) {
    right: rem(50px);
    left: rem(50px);
  }
}

.content p {
  margin-top: 0;
}

.content p:first-child {
  padding-top: rem(80px);

  @media (min-width: 800px) {
    padding-top: rem(208px);
  }
}

.inner {
  height: 100%;
}

.headline {
  font-size: rem(16px);
  position: absolute;
  z-index: 1;
  left: rem(120px);

  @media (min-width: 800px) {
    font-size: rem(25px);
    left: rem(280px);
  }
}

.left .headline {
  @media (min-width: 800px) {
    left: rem(200px);
  }
}

.headline + .content p:first-child {
  padding-top: rem(150px);

  @media (min-width: 800px) {
    padding-top: rem(250px);
  }
}

.circle {
  position: absolute;
  z-index: 0;
}
