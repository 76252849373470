.root {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.slide {
  width: 10000px;
  height: 100%;
  position: relative;
  display: flex;
  z-index: 0;
}
.slide > div {
  position: relative;
  width: 100vw;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.arrows svg {
  cursor: pointer;
  padding: 0.625rem;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 6;
  margin: auto;
}
@media (max-width: 800px) {
  .arrows svg {
    width: 0.9375rem;
    height: 0.9375rem;
  }
  .arrows svg:not(.right) {
    left: 0.8125rem;
  }
}
.arrows svg.right {
  right: 0;
}
@media (max-width: 800px) {
  .arrows svg.right {
    right: 0.8125rem;
  }
}
@media (min-width: 800px) {
  .arrows {
    right: 1.25rem;
    left: 1.25rem;
  }
}