.andrew-coimbra {
  --profile-color: #343434;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619299674/andrew_coimbra/andrew_fabric_background_image_w2flgn.jpg");
}

.angus-tsui {
  --profile-color: #515844;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1618951262/angus_tsui/angus_fabric_background_image_vrrc6m.jpg");
}

.annaiss-yucra {
  --profile-color: #faa0cf;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619299728/annaiss_yucra/annaiss_fabric_background_image_yrakw2.jpg");
}

.annelijn-hartevelt {
  --profile-color: #9aa0a6;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619299761/annelijn_hartevelt/annelijn_fabric_background_image_yda80e.jpg");
}

.florentina-leitner {
  --profile-color: #367735;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619299793/florentina_leitner/florentina_fabric_background_image_pqpkmb.jpg");
}

.jenn-lee {
  --profile-color: #7CBEC3;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619299824/jenn_lee/jenn_fabric_background_image_k4tzvb.jpg");
}

.katrin-aasmaa {
  --profile-color: #edaa61;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619299872/katrin_aasmaa/katrin_fabric_background_image_eda8uf.jpg");
}

.ns-gaia-sidharth-sinha {
  --profile-color: #ba6431;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619300189/ns_gaia/ns_gaia_fabric_background_image_z8nen3.jpg");
}

.silpa-myno-macheda-and-mehreen-asma {
  --profile-color: #2632ad;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619300254/silpa/silpa_fabric_background_image_rnjkvn.jpg");
}

.two-point-two-anvita-sharma {
  --profile-color: #596169;
  --profile-fabric: url("https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_600,h_600,c_lfill/v1619300327/two_point_two/tpt_fabric_background_image_eshszh.jpg");
}