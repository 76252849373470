// for regular desktop size and large phones, smaller tablets
:root {
  font-size: 16px;
}

// for smaller desktops
@media (min-width: 1000px) and (max-width: 1200px) {
  :root {
    font-size: 14px;
  }
}

// for small desktops and tablets
@media (min-width: 800px) and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}

// for full-sized desktops
@media (min-width: 1550px) {
  :root {
    font-size: 18px;
  }
}

// for smaller phones
@media (max-height: 700px) {
  :root {
    font-size: 13px;
  }
}

// Test Styles
$body-background-primary: #161616;
$body-copy-primary: #f2f2f2;

html {
  width: 100%;
  height: 100%;
}

body {
  color: $body-copy-primary;
  font: $zona-pro-regular;
  margin: 0;

  width: 100%;
}

a {
  color: white;
}

body {
  position: relative;
  background-color: $body-background-primary;

  #logo {
    background-image: url("~images/SwatchOn_logo_white.svg");
    background-repeat: no-repeat;

    min-width: rem(300px);
    min-height: rem(50px);
  }
}

// ---------------------------------------------------------------------------
// Swatch / Video Mask
// ---------------------------------------------------------------------------
#intro-mask-video-container {
  background-color: $body-background-primary;

  width: 100vw;
  height: 100vh;

  position: fixed;
  z-index: 10;

  /* Other `mask` styles */
  /*
    mask-clip
    mask-composite
    mask-image
    mask-mode
    mask-origin
    mask-position
    mask-repeat
    mask-size
  */

  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 10%;

  video {
    position: absolute;
    object-fit: cover;

    width: 100%;
    height: 100%;
  }
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
