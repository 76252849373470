@import "../rem";
@import "../sizes";

.root {
  background-color: white;
  position: relative;
  z-index: 400;
  overflow: hidden;
  width: rem($mobile-size);
  height: rem($mobile-size);

  // background-image: url(https://res.cloudinary.com/hmmdysfdl/image/upload/dpr_auto,q_auto:good,w_800,h_1024,c_lfill/v1618951266/angus_tsui/angus_3d_poster_cbpbi9.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 800px) {
    // border-radius: 999em;
    width: rem($desktop-size);
    height: rem($desktop-size);
  }
}

@media (max-width: 800px) {
  $size: rem(700px);

  .video {
    position: relative;
    margin-left: $size / -1.9;
    left: 50%;

    @media (min-width: 800px) {
      margin-left: $size / -2;
    }
  }

  .video,
  .video video {
    width: auto;
    height: 100%;
    // margin-left: -2%;

    @media (min-width: 800px) {
      // width: $size;
    }
  }
}

.closeButton {
  position: absolute;
  z-index: 2;
  top: rem(20px);
  left: rem(20px);
  height: rem(30px);
  width: rem(30px);

  @media (min-width: 800px) {
    display: none;
  }
}

.closeButton line {
  stroke: black;
}

@media (min-width: 800px) {
  $size: rem($desktop-size * 1.778);

  .video {
    position: relative;
    margin-left: $size / -2;
    left: 50%;
  }

  .video,
  .video video {
    height: 100%;
    width: $size;
  }
}
