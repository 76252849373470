@import "../javascripts/future_of_fabric/react/rem";

// @import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import "future_of_fabric/fonts";
@import "future_of_fabric/main";
@import "future_of_fabric/profiles";


h2 {
  font-weight: normal;
}
