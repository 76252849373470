// ---------------------------------------------------------------------------
// Altero
// ---------------------------------------------------------------------------
@font-face {
  font-family: "alteroregular";
  src: url("~altero-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
$altero-regular: normal normal 16px "alteroregular", Arial, sans-serif;

// ---------------------------------------------------------------------------
// Tomato Grotesk
// ---------------------------------------------------------------------------
@font-face {
  font-family: "tomato_groteskregular";
  src: url("~tomatogrotesk-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
$tomato-grotesk-regular: normal normal 16px "tomato_groteskregular", Arial,
  sans-serif;

@font-face {
  font-family: "tomato_groteskbold";
  src: url("~tomatogrotesk-bold-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
$tomato-grotesk-bold: normal normal 16px "tomato_groteskbold", Arial, sans-serif;

// ---------------------------------------------------------------------------
// Zona Pro
// ---------------------------------------------------------------------------
@font-face {
  font-family: "zona_problack";
  src: url("~zonapro-black-webfont.woff2") format("woff2");
  font-weight: bolder;
  font-style: normal;
}
$zona-pro-black: normal normal 16px "zona_problack", Arial, sans-serif;

// @font-face {
//   font-family: "zona_problack_italic";
//   src: url("~zonapro-blackitalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-black-italic: normal normal 16px "zona_problack_italic", Arial,
//   sans-serif;

@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
$zona-pro-bold: normal normal 16px "zona_proregular", Arial, sans-serif;

@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-bolditalic-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}
$zona-pro-bold-italic: normal normal 16px "zona_proregular", Arial, sans-serif;

@font-face {
  font-family: "zona_proextrabold";
  src: url("~zonapro-extrabold-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
$zona-pro-extra-bold: normal normal 16px "zona_proextrabold", Arial, sans-serif;

// @font-face {
//   font-family: "zona_proextrabold_italic";
//   src: url("~zonapro-extrabolditalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-extra-bold-italic: normal normal 16px "zona_proextrabold_italic",
//   Arial, sans-serif;

// @font-face {
//   font-family: "zona_prohairline";
//   src: url("~zonapro-hairline-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-hairline: normal normal 16px "zona_prohairline", Arial, sans-serif;

// @font-face {
//   font-family: "zona_prohairline_italic";
//   src: url("~zonapro-hairlineitalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-hairline-italic: normal normal 16px "zona_prohairline_italic", Arial,
//   sans-serif;

@font-face {
  font-family: "zona_prolight";
  src: url("~zonapro-light-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
$zona-pro-light: normal normal 16px "zona_prolight", Arial, sans-serif;

// @font-face {
//   font-family: "zona_prolightitalic";
//   src: url("~zonapro-lightitalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-light-italic: normal normal 16px "zona_prolightitalic", Arial,
//   sans-serif;

@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
$zona-pro-regular: normal normal 16px "zona_proregular", Arial, sans-serif;

@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-regularitalic-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}
$zona-pro-regular-italic: normal normal 16px "zona_proregular", Arial,
  sans-serif;

// @font-face {
//   font-family: "zona_prosemibold";
//   src: url("~zonapro-semibold-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-semibold: normal normal 16px "zona_prosemibold", Arial, sans-serif;

// @font-face {
//   font-family: "zona_prosemibold_italic";
//   src: url("~zonapro-semibolditalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-semibold-italic: normal normal 16px "zona_prosemibold_italic", Arial,
//   sans-serif;

// @font-face {
//   font-family: "zona_prothin";
//   src: url("~zonapro-thin-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-thin: normal normal 16px "zona_prothin", Arial, sans-serif;

// @font-face {
//   font-family: "zona_prothin_italic";
//   src: url("~zonapro-thinitalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-thin-italic: normal normal 16px "zona_prothin_italic", Arial,
//   sans-serif;

// @font-face {
//   font-family: "zona_proultra";
//   src: url("~zonapro-ultra-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-ultra: normal normal 16px "zona_proultra", Arial, sans-serif;

// @font-face {
//   font-family: "zona_proultra_italic";
//   src: url("~zonapro-ultraitalic-webfont.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// $zona-pro-ultra-italic: normal normal 16px "zona_proultra_italic", Arial,
//   sans-serif;
