.root {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

.root video {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}