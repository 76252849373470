.root {
  position: fixed;
  z-index: 999;
  padding: 0.0625rem;
  top: -0.0625rem;
  left: -0.0625rem;
  outline: none;
  box-sizing: border-box;
  width: 5rem;
  height: 4.6875rem;
}

.toggle {
  position: absolute;
  padding: 1.25rem;
}

.toggle svg {
  width: 2.5rem;
  height: 2.1875rem;
  position: absolute;
  z-index: 1;
}

#menuContainer {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
  border-radius: 0 50% 50% 50%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.75);
  transform: scale(0);
  transform-origin: top left;
  transition-property: transform width height;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-delay: 0s;
}
#menuContainer .menu a {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-delay: 0s;
}
#menuContainer.open {
  width: 25rem;
  height: 25rem;
  transform: scale(1);
}
#menuContainer.open .menu a {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-delay: 0.5s;
}

.menu {
  width: 17.0625rem;
  height: 17.0625rem;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  top: 3.125rem;
  left: 3.125rem;
  flex-direction: column;
}

.menu a {
  color: inherit;
  text-decoration: inherit;
  text-transform: uppercase;
  text-align: center;
  display: block;
}

.menu small {
  display: block;
}

.swatch {
  position: absolute;
  left: 8.375rem;
  top: 3.5625rem;
  z-index: 0;
  height: 2.5rem;
}

.of {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  font-size: 0.8em;
  transform: translateY(-0.0625rem);
  display: inline-block;
}