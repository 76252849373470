@import "../rem";

.toggle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;

  svg {
    width: rem(100px);
    height: rem(100px);
    cursor: pointer;

    // @media (min-width: 800px) {
    //   width: rem(300px);
    //   height: rem(300px);
    // }
  }
}

.root,
.video video,
.video {
  width: 100%;
  height: 100%;
}

.video {
  background-color: #000;
}

.zoom {
  $size: rem(700px);

  position: relative;
  margin-left: $size / -1.9;
  left: 50%;
  width: $size;
  height: 100%;

  @media (min-width: 800px) {
    margin-left: $size / -2;
  }

  video {
    width: auto;
    height: 100%;

    @media (min-width: 800px) {
      width: $size;
    }
  }
}

.zoom {
  width: 150%;

  @media (min-width: 800px) {
    width: auto;
    left: 0;
    margin-left: 0px;
    display: flex;
    justify-content: center;

    video {
      width: auto;
    }
  }
}

.controls {
  position: absolute;

  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
