.root {
  background-color: white;
  position: relative;
  z-index: 400;
  overflow: hidden;
  width: 26.25rem;
  height: 26.25rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 800px) {
  .root {
    width: 39.875rem;
    height: 39.875rem;
  }
}

@media (max-width: 800px) {
  .video {
    position: relative;
    margin-left: -23.0263157895rem;
    left: 50%;
  }
}
@media (max-width: 800px) and (min-width: 800px) {
  .video {
    margin-left: -21.875rem;
  }
}
@media (max-width: 800px) {
  .video,
.video video {
    width: auto;
    height: 100%;
  }
}
.closeButton {
  position: absolute;
  z-index: 2;
  top: 1.25rem;
  left: 1.25rem;
  height: 1.875rem;
  width: 1.875rem;
}
@media (min-width: 800px) {
  .closeButton {
    display: none;
  }
}

.closeButton line {
  stroke: black;
}

@media (min-width: 800px) {
  .video {
    position: relative;
    margin-left: -35.448875rem;
    left: 50%;
  }

  .video,
.video video {
    height: 100%;
    width: 70.89775rem;
  }
}