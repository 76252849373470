@import "rem";

.profile {
  width: rem(300px);
  height: rem(300px);
  position: absolute;
  text-align: center;
  font-size: rem(20px);
  margin-bottom: auto;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 1s;

  @media (min-width: 800px) {
    width: rem(400px);
    height: rem(400px);
    transform: scale(0.1);
  }
}

.profileContent {
  background-size: cover;
  border-radius: 999em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 800px) {
  .mobileScrollName {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
