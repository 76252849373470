@import "rem";

.root {
}

.iconScroll {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: rem(20px);
  pointer-events: none;
  z-index: 10000;

  svg {
    max-width: rem(50px);
    max-height: rem(50px);

    @media (min-width: 800px) {
      max-width: rem(72px);
      max-height: rem(72px);
    }
  }
}