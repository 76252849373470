.iconScroll {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 1.25rem;
  pointer-events: none;
  z-index: 10000;
}
.iconScroll svg {
  max-width: 3.125rem;
  max-height: 3.125rem;
}
@media (min-width: 800px) {
  .iconScroll svg {
    max-width: 4.5rem;
    max-height: 4.5rem;
  }
}