@use "sass:list";
@use "sass:map";
@use "sass:math";
@import "rem";
@import "about.vars";

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  /* transition: 1s background-image; */
  background-position-y: center;
  transition: all 1s;
}

.section {
  position: fixed;
  top: rem(55px);
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-size: cover;
  /* transition: 1s background-image; */
  background-position-y: center;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 800px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;

    top: rem(0px);

    width: rem(620px);
    height: rem(450px);
  }
}

.imageSequenceContainer,
.videoSequenceContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: rem(300px);
  height: rem(350px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  margin-top: rem(-90px);
  margin-bottom: rem(-80px);

  transform: scale(.60);

  @media (min-width: 360px) {
    margin-top: rem(-50px);
    margin-bottom: rem(-30px);

    transform: scale(.75);
  }

  @media (min-width: 800px) {
    height: rem(400px);

    margin-top: rem(0px);
    margin-bottom: rem(0px);

    transform: scale(1);
  }
}

.videoSequenceContainer {
  align-items: center;

  @media (min-width: 800px) {
    align-items: flex-start;
  }

  .masked {
    mask-size: 95%;

    @media (min-width: 800px) {
      mask-size: auto;
    }
  }
}

.videoBackgroundContainer {
}

.imageSequenceBackground {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;

  width: rem(250px);
  height: rem(355px);

  position: absolute;
  z-index: 1;

  @media (min-width: 800px) {
    width: rem(300px);
    height: rem(402px);
  }
}

.imageSequenceLogo {
  background-size: contain;
  background-repeat: no-repeat;

  width: rem(205px);
  height: rem(75px);

  position: absolute;
  z-index: 2;

  transform: translate(rem(-50px), rem(5px));

  @media (min-width: 800px) {
    transform: translate(rem(-70px), rem(5px));
  }
}

.sequenceCacher {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  div {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
  }
}

.imageSequence {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1s;

  width: rem(250px);
  height: rem(355px);

  position: relative;
  z-index: 3;

  @media (min-width: 800px) {
    width: rem(300px);
    height: rem(402px);
  }
}

.imageSequenceContainer {
  > .imageSequence:not(.masked) {
    background-size: contain;
    // width: rem(250px);
    // height: rem(355px);

    @media (min-width: 800px) {
      margin-top: -32px;
    }
  }

  // Adjusts the `.imageSequence` only if `.imageSequenceLogo` is present
  // Kind of a hack for the special case that is 5.2.0
  div:nth-of-type(2) + div {
    background-size: cover !important;
    transform: translate(rem(35px), rem(20px));

    @media (min-width: 800px) {
      margin-top: 0px !important;
    }
  }
}

// .video {
//   float: left;
// }

.video {
  width: rem(300px);
  height: rem(350px);

  @media (min-width: 800px) {
    height: rem(400px);
  }

  video {
    height: 100%;
    width: 100%;
  }
}

.masked {
  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: auto;

  // video {
  //   position: absolute;
  //   object-fit: cover;

  //   width: 100%;
  //   height: 100%;
  // }
}

.contentList {
  line-height: 1.3;
  font-size: rem(16px);

  @media (max-width: 800px) {
    height: rem(250px);
    width: 100%;
  }
}

.content {
  transition: all 1s;
  // position: absolute;

  @media (max-width: 800px) {
    // padding: 0 rem(20px);
    // left: 0;
    // right: 0;
  }

  @media (min-width: 800px) {
    // top: rem(80px);
    // left: rem(327px);
  }
}

.contentContainer {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-start;

  position: relative;

  padding: 0 rem(20px);
  padding-top: rem(20px);

  @media (min-width: 800px) {
    // padding: 0 rem(20px);
    padding-top: rem(40px);
  }

  .contentList {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    justify-content: flex-start;

    position: relative;

    .content {
      position: absolute;
      top: 0;
      font-size: small;

      &:last-of-type {
        position: static;
      }

      &:only-of-type {
        position: relative;
      }

      // @media (min-height: 768px) and (min-width: 360px) {
      //   font-size: 1rem;
      // }
    }
  }
}

.headline {
  width: 100%;
  // margin-top: 25px;

  h2 {
    margin: 0;
    font-size: rem(20px);
    text-align: left;
  }

  @media (min-width: 800px) {
    // position: absolute;
    // left: rem(317px);

    h2 {
      text-align: left;
      font-size: rem(23px);
      // padding: 0 rem(10px);
      // margin-bottom: unset;
    }
  }
}

.menu {
  position: absolute;
  bottom: rem(-195px);
  width: rem(340px);
  max-height: rem(340px);
  margin: auto;
  left: 0;
  right: 0;
  z-index: 4;

  @media (min-width: 800px) {
    margin: initial;
    bottom: rem(-100px);
    right: rem(-100px);
    left: initial;
  }
}

.menu > svg {
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  @media (min-width: 800px) {
    opacity: 1;
  }
}

.menuItems {
  position: absolute;
  text-transform: uppercase;
  font-size: rem(10px);
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: rem(70px);
  text-align: center;
  align-items: center;

  @media (min-width: 800px) {
    display: block;
    right: rem(120px);
    top: rem(50px);
    font-size: rem(14px);
    justify-content: center;
  }
}

.menuItems a {
  display: block;
  text-decoration: none;
  color: inherit;

  @media (min-width: 800px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: rem(20px);
  }
}

// .menuItems > *:first-child {
//   margin-right: rem(20px);

//   @media (min-width: 800px) {
//     margin-right: 0;
//   }
// }

.menuItems svg {
  margin-bottom: 10px;
  height: 22px;

  @media (min-width: 800px) {
    margin-bottom: 0;
    width: 32px;
    height: initial;
    margin-left: 10px;
  }
}

.floaters svg {
  position: absolute;
  z-index: 1;
}

.floaters svg:nth-child(1) {
  animation: float1 5s linear infinite;
}

@keyframes float1 {
  0% {
    transform: translateY(-1000px);
  }

  100% {
    transform: translateY(1000px);
  }
}

@for $n from 1 through 10 {
  $len: 3;

  .floaters svg:nth-child(#{$n}) {
    transform: translateY(-3000px);
    animation: float#{$n} #{3 + $len + math.random($len)}s linear infinite;
    animation-delay: #{random(20)}s;
  }

  @keyframes float#{$n} {
    $x: $n * 100px + random(100px);
    $scale: math.random() + 0.5;

    0% {
      transform: scale($scale) translate($x, -1500px - math.random(300px));
    }

    100% {
      transform: scale($scale) translate($x, 2000px + math.random(2000px));
    }
  }
}

.backgroundVideo,
.backgroundVideo video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}
