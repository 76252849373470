@import "../rem";
@import "../sizes";

.root {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.slide {
  width: 10000px;
  height: 100%;
  position: relative;
  display: flex;
  z-index: 0;

  > div {
    position: relative;
    width: 100vw;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}

.arrows {
  svg {
    cursor: pointer;
    padding: rem(10px); // allow for bigger hit area
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 6;
    margin: auto;

    @media (max-width: 800px) {
      width: rem(15px);
      height: rem(15px);
      &:not(.right) {
        left: rem(13px);
      }
    }
  }

  svg.right {
    right: 0;

    @media (max-width: 800px) {
      right: rem(13px);
    }
  }

  @media (min-width: 800px) {
    right: rem(20px);
    left: rem(20px);
  }
}
