@font-face {
  font-family: "alteroregular";
  src: url("~altero-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tomato_groteskregular";
  src: url("~tomatogrotesk-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tomato_groteskbold";
  src: url("~tomatogrotesk-bold-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zona_problack";
  src: url("~zonapro-black-webfont.woff2") format("woff2");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-bolditalic-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "zona_proextrabold";
  src: url("~zonapro-extrabold-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zona_prolight";
  src: url("~zonapro-light-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zona_proregular";
  src: url("~zonapro-regularitalic-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}
:root {
  font-size: 16px;
}

@media (min-width: 1000px) and (max-width: 1200px) {
  :root {
    font-size: 14px;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}
@media (min-width: 1550px) {
  :root {
    font-size: 18px;
  }
}
@media (max-height: 700px) {
  :root {
    font-size: 13px;
  }
}
html {
  width: 100%;
  height: 100%;
}

body {
  color: #f2f2f2;
  font: normal normal 16px "zona_proregular", Arial, sans-serif;
  margin: 0;
  width: 100%;
}

a {
  color: white;
}

body {
  position: relative;
  background-color: #161616;
}
body #logo {
  background-image: url("~images/SwatchOn_logo_white.svg");
  background-repeat: no-repeat;
  min-width: 18.75rem;
  min-height: 3.125rem;
}

#intro-mask-video-container {
  background-color: #161616;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  /* Other `mask` styles */
  /*
    mask-clip
    mask-composite
    mask-image
    mask-mode
    mask-origin
    mask-position
    mask-repeat
    mask-size
  */
  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 10%;
}
#intro-mask-video-container video {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

#profile-1-0-0 .designer-info {
  background-color: rgba(35, 51, 35, 0.9);
}
h2 {
  font-weight: normal;
}