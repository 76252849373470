.root {
  border-radius: 999px;
  position: absolute;
  width: 26.25rem;
  height: 26.25rem;
  transform: translateY(-3.125rem) translateX(-50%);
  left: 50%;
  z-index: 5;
  border-radius: 999em;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}
@media (min-width: 800px) {
  .root {
    width: 39.875rem;
    height: 39.875rem;
    left: 0;
    transform: none;
  }
}

.slide {
  width: 10000px;
  position: relative;
  display: flex;
  z-index: 5;
}

.photo {
  position: relative;
  width: 26.25rem;
  height: 26.25rem;
  background-size: cover;
  z-index: 4;
}
@media (min-width: 800px) {
  .photo {
    width: 39.875rem;
    height: 39.875rem;
  }
}

.arrows svg {
  cursor: pointer;
  padding: 0.625rem;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 6;
  margin: auto;
}
@media (max-width: 800px) {
  .arrows svg {
    width: 0.9375rem;
    height: 0.9375rem;
  }
  .arrows svg:not(.right) {
    left: 0.8125rem;
  }
}
.arrows.renderingsArrows svg path {
  stroke: var(--profile-color);
}
.arrows svg.right {
  right: 0;
}
@media (max-width: 800px) {
  .arrows svg.right {
    right: 0.8125rem;
  }
}
@media (min-width: 800px) {
  .arrows {
    right: 1.25rem;
    left: 1.25rem;
  }
}

.profileName {
  position: absolute;
  text-transform: uppercase;
  text-align: left;
  transition: 0.1s opacity;
  opacity: 0;
  font-size: 1.375rem;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 99999;
  width: 13.75rem;
  height: 9.375rem;
  transform: translate(6.25rem, -13.75rem);
}
.profileName strong {
  font-size: 1.75rem;
}
@media (min-width: 800px) {
  .profileName {
    font-size: 0.9375rem;
    transform: translate(8.125rem, -18.4375rem);
    font-size: 28px;
    /* text-shadow: var(--profile-color) 0.5px 0.5px; */
  }
  .profileName strong {
    font-size: 2.25rem;
  }
}

.wornBy {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-size: 0.75rem;
  position: absolute;
  bottom: 5rem;
  width: 9.375rem;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.wornBy strong {
  font-size: 1.375rem;
}

.instagram {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.3125rem;
}