@use 'sass:math';
@import "rem";

$size: rem(400px);
$offset: rem(-45px);

.root {
  position: fixed;
  z-index: 999;
  padding: rem(1px);
  top: rem(-1px);
  left: rem(-1px);
  outline: none;
  box-sizing: border-box;
  width: rem(80px);
  height: rem(75px);
}

.toggle {
  position: absolute;
  padding: rem(20px);
}

.toggle svg {
  width: rem(40px);
  height: rem(35px);
  position: absolute;
  z-index: 1;
}

#menuContainer {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  // top: $offset;
  // left: $offset;
  width: 0;
  height: 0;
  border-radius: 0 50% 50% 50%;
  // -webkit-backdrop-filter: blur(4px);
  // filter: blur(5px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.75);
  transform: scale(0);
  transform-origin: top left;
  // transition: all 0.5s;
  transition-property: transform width height;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-delay: 0.0s;

  // opacity: 0;

  // .bg {
  //   position: absolute;
  //   z-index: 0;
  //   border-radius: 999em;
  //   backdrop-filter: blur(4px);
  //   background-color: rgba(0, 0, 0, 0.3);
  //   top: $offset;
  //   left: $offset;
  //   width: $size;
  //   height: $size;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   transform: scale(0);
  //   transform-origin: top left;
  //   transition: all 0.5s;

  //   flex-direction: column;
  // }

  .menu {
    a {
      opacity: 0;
      // transition-delay: 30s;
      transition-property: opacity;
      transition-timing-function: ease-in-out;
      transition-duration: 0.25s;
      transition-delay: 0.0s;
    }
  }

  &.open {
    // display: block;
    width: $size;
    height: $size;
    // opacity: 1;
    transform: scale(1);

    // .bg {
    //   transform: scale(1);
    // }

    .menu {
      a {
        opacity: 1;

        transition-property: opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.25s;
        transition-delay: 0.5s;
      }
    }
  }
}

.menu {
  // position: absolute;
  // z-index: 0;
  width: rem(273px);
  height: rem(273px);
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  top: rem(50px);
  left: rem(50px);

  flex-direction: column;
}

.menu a {
  color: inherit;
  text-decoration: inherit;
  text-transform: uppercase;
  text-align: center;
  display: block;
}

.menu small {
  display: block;
}

.swatch {
  position: absolute;
  left: rem(134px);
  top: rem(57px);
  z-index: 0;
  height: rem(40px);
}

.of {
  margin-left: rem(10px);
  margin-right: rem(10px);
  font-size: 0.8em;
  transform: translateY(rem(-1px));
  // line-height: 2em;
  display: inline-block;
}
