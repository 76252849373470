.view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  perspective: 0;
}

.main {
  width: 25rem;
  height: 25rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.main svg, .main img {
  width: 25rem;
  height: 25rem;
}

.names {
  text-transform: uppercase;
  font-weight: bolder;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 25rem;
  height: 25rem;
  font-size: 1.875rem;
}

.names div {
  position: absolute;
}

.canada {
  top: -9.875rem;
  left: 8.3125rem;
}

.peru {
  top: -7.125rem;
  left: 16.8125rem;
  font-size: 1.125rem;
}

.austria {
  top: -4.75rem;
  left: 13.125rem;
  font-size: 1.25rem;
}

.estonia {
  top: 25.75rem;
  left: 13.3125rem;
  font-size: 1.5625rem;
}

.england {
  top: 31.8125rem;
  left: 3.25rem;
  font-size: 1rem;
}

.india {
  top: 29.875rem;
  left: 14.0625rem;
  font-size: 1.25rem;
}

.taiwan {
  top: 27.75rem;
  left: 1.1875rem;
  font-size: 1.25rem;
}

.hongKong {
  top: -3.375rem;
  left: 1.375rem;
  font-size: 1.5625rem;
}

.holland {
  top: -6.5rem;
  left: 1rem;
  font-size: 1rem;
}

@media (min-width: 800px) {
  .names div {
    font-size: inherit;
  }

  .canada {
    top: -3rem;
    left: 8.3125rem;
  }

  .peru {
    top: 0.875rem;
    left: 22.25rem;
  }

  .austria {
    top: 6.25rem;
    left: 26.0625rem;
  }

  .estonia {
    top: 17rem;
    left: 26.4375rem;
  }

  .england {
    top: 26.25rem;
    left: 16.125rem;
  }

  .india {
    top: 25.8125rem;
    left: -1.9375rem;
  }

  .taiwan {
    top: 18.75rem;
    left: -9.5rem;
  }

  .hongKong {
    top: 6.25rem;
    left: -13.6875rem;
  }

  .holland {
    top: 1.6875rem;
    left: -7.375rem;
  }
}