.bg {
  width: 130%;
  height: 130%;
  margin-top: -15%;
  margin-left: -15%;
  position: relative;
  z-index: 1;
}

.content {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
