.root {
  position: relative;
  z-index: 400;
  overflow: hidden;
  width: 26.25rem;
  height: 26.25rem;
}
@media (min-width: 800px) {
  .root {
    width: 39.875rem;
    height: 39.875rem;
  }
}