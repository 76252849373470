.root {
  background-color: var(--profile-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0) 60%, var(--profile-color) 100%), var(--bgi);
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.noPointer {
  pointer-events: none;
}

.root p {
  margin-top: 0;
}