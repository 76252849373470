@import "../rem";
@import "../sizes";

.root {
  position: relative;
  z-index: 400;
  overflow: hidden;
  width: rem($mobile-size);
  height: rem($mobile-size);

  @media (min-width: 800px) {
    width: rem($desktop-size);
    height: rem($desktop-size);
  }
}
