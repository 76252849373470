#profile-1-0-0 {
  .designer-info {
    background-color: rgba(#233323, 0.9);

    #profile-1-1-1 {
      // background-image: url('https://res.cloudinary.com/hmmdysfdl/image/upload/v1618951262/angus_tsui/angus_fabric_background_image_vrrc6m.jpg')
    }
  }

  .designer-video {
  }

  .designer-work {

  }

  .designer-renderings {

  }
}