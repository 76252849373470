@use "sass:math";
@import "rem";

@media (max-width: 800px) {
  .root {
    // position: relative;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: rem(70px);
    transform: translateY(rem(250px));
    z-index: 1;
  }
}

.navigating {
  .info,
  .work,
  .video,
  .renderings {
    opacity: 0;
  }
}

.info,
.work,
.video,
.renderings {
  border-radius: 999em;
  width: rem(60px);
  height: rem(60px);

  display: block;
  position: relative;
  transition: all 0.25s;
  opacity: 1;

  border-color: grey;
  border-width: 3px;
  border-style: solid;

  &::before {
    background-image: var(--bgi);
    background-size: cover;
    background-position: center;
    background-color: var(--profile-color);

    $x: 1px;
    content: " ";
    border-radius: 999em;
    position: absolute;
    top: rem($x);
    right: rem($x);
    bottom: rem($x);
    left: rem($x);
  }
}

.selected {
  border-color: white;
}

@media (min-width: 800px) {
  .info,
  .work,
  .video,
  .renderings {
    position: absolute;

    width: rem(130px);
    height: rem(130px);

    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 401;

    &::before {
      $x: 2px;
      top: $x;
      right: $x;
      bottom: $x;
      left: $x;
    }

    border-width: 3px;

    &:hover {
      border-width: 8px;
    }

    &.selected {
      border-width: 8px;
    }
  }

  $scale: rem(420px);
  $d: 120deg;
  $s: -21.5deg;

  .info {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(1);
  }
  .navigating .info {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(0);
  }

  $d: $d + $s;

  .video {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(1);
  }
  .navigating .video {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(0);
  }

  $d: $d + $s;

  .work {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(1);
  }
  .navigating .work {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(0);
  }

  $d: $d + $s;

  .renderings {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(1);
  }
  .navigating .renderings {
    transform: translate(math.sin($d) * $scale, math.cos($d) * $scale) scale(0);
  }
}
