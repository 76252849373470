@import "../rem";

.popup {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.closeButton {
  position: absolute;
  z-index: 2;
  top: rem(20px);
  left: rem(20px);
  height: rem(30px);
  width: rem(30px);
  cursor: pointer;

  line {
    // stroke: black;
    stroke: #fff;
  }
}
