@charset "UTF-8";
.root {
  position: relative;
  width: 26.25rem;
  height: 26.25rem;
  z-index: 400;
}

.root p {
  margin-top: 0;
}

.inner {
  height: 100%;
}

.root::before {
  content: "";
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: polygon(0 0, 98% 0, 50% 6%, 23.4% 17.3%, 6% 32.6%, 0 50%, 6% 65.6%, 23.4% 82.7%, 50% 94%, 98% 100%, 0 100%);
  shape-margin: 0%;
}

.inner::before {
  content: "";
  width: 50%;
  height: 100%;
  float: right;
  shape-outside: polygon(2% 0%, 100% 0%, 100% 100%, 2% 100%, 50% 94%, 76.6% 82.7%, 94% 65.6%, 100% 50%, 94% 32.6%, 76.6% 17.3%, 50% 6%);
  shape-margin: 0%;
}

.root::before,
.inner::before {
  shape-margin: 5%;
}

.root p {
  margin-top: 0;
}

.content {
  padding-top: 2.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  height: 80%;
  position: absolute;
  padding: 0 10%;
  padding-top: 20%;
  box-sizing: border-box;
}

.page-1 {
  padding-top: 5.625rem;
}

.headline {
  text-transform: uppercase;
  font-family: "tomato_groteskregular", Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.375rem;
}

.headline strong {
  display: inline-block;
  font-family: "zona_proextrabold";
  font-size: 1.5rem;
}
.headline strong:before {
  content: " ";
}

.copy {
  margin-top: 2.1875rem;
  font-family: "tomato_groteskregular", Arial, sans-serif;
  font-size: 0.8125rem;
}
.copy strong {
  font-family: "tomato_groteskbold", Arial, sans-serif;
}

.swatchLink {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: auto;
  top: 2rem;
  right: 0;
  left: 0;
  width: auto;
  text-align: center;
  z-index: 1;
  text-decoration: none;
}
.swatchLink:hover {
  text-decoration: underline;
}
.swatchLink small {
  font-family: "tomato_groteskregular", Arial, sans-serif;
  font-size: 0.75rem;
  padding: 0.625rem 0;
}

.swatchLink svg {
  max-height: 3rem;
  max-width: 2.375rem;
}

.swatchLink + div:first-of-type .content {
  height: 100%;
  justify-content: flex-start;
  padding-top: 8.125rem;
}

@media (max-width: 800px) {
  .content {
    font-size: 0.75rem;
    line-height: 1.3;
  }
}
.page-1::before {
  content: " ";
  mask-image: url("~images/swatch_icon_mask.svg");
  mask-position: center 15rem;
  mask-repeat: no-repeat;
  mask-size: 115%;
  position: absolute;
  top: 0;
  right: -1.125rem;
  bottom: -0.4375rem;
  left: -1.0625rem;
  background-image: var(--profile-fabric);
  background-size: cover;
}
@media (min-width: 800px) {
  .page-1::before {
    mask-position: center 18.75rem;
  }
}

.websiteLinks {
  position: absolute;
  display: flex;
  right: 0;
  bottom: 4.375rem;
  left: 0;
  justify-content: center;
  z-index: 1;
}

.websiteLinks a {
  width: 1.875rem;
  height: 1.875rem;
}

.websiteLinks svg {
  width: 100%;
}

.websiteLinks a:first-child {
  margin-right: 1.25rem;
}

.websiteLinks a:only-of-type {
  margin-right: 0;
}

@media (min-width: 800px) {
  .page-1::before {
    right: 0;
    bottom: 0;
    left: 0;
    mask-position: center 20.625rem;
  }

  .websiteLinks {
    bottom: 5.5rem;
  }

  .root {
    width: 39.875rem;
    height: 39.875rem;
    z-index: 400;
    overflow: hidden;
  }

  .root::before,
.inner::before {
    shape-margin: 15%;
  }

  .headline {
    font-size: 1.875rem;
    line-height: 2rem;
  }

  .headline strong {
    font-size: 2rem;
  }

  .copy {
    font-size: 1.25rem;
  }

  /* .andrew-coimbra {
    padding-top: 200px;
  }

  .angus-tsui {
    padding-top: 200px;
  }

  .annaiss-yucra {
    padding-top: 200px;
  }

  .annelijn-hartevelt {
    padding-top: 200px;
  }

  .florentina-leitner {
    padding-top: 200px;
  }

  .jenn-lee {
    padding-top: 200px;
  }

  .katrin-aasmaa {
    padding-top: 200px;
  }

  */
}